<template>
  <div class="t_work_online">
    <div class="stu-module-header">
      <div class="stu-module-title">在线作业</div>
      <div>
        <el-button type="primary" @click="add">添加在线作业</el-button>
        <el-button type="primary" @click="detail">查看学生作业情况</el-button>
      </div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline ref="form">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="批阅截止时间" prop="date">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
          <el-button @click="reset" type="primary">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="tableData"
        stripe
        @selection-change="selectionChange"
        :height="height"
      >
        <el-table-column
          type="selection"
          align="center"
          width="65"
        ></el-table-column>
        <el-table-column
          label="序号"
          align="center"
         type="index"
          width="65"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :label="item.label"
          :prop="item.prop"

          align="center"
          :min-width="item.width"
          show-overflow-tooltip
        >

        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="setSemester(scope.row)"
              >设置学期</el-button
            >
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button type="text" @click="preview(scope.row)">
              预览
            </el-button>
            <el-button type="text" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
      >
      </el-pagination>
    </div>
    <SetSemester ref="semesterRef" :items="selectRows" />
  </div>
</template>

<script>
import SetSemester from "./module/SetSemester.vue";
export default {
  name: "t_work_online",
  components: { SetSemester },
  created(){
    this.query()
  },
  activated() {
    this.query()
    },
  data() {
    return {
      height: window.innerHeight - 400,
      form: {
        name: "",
        date: ['',''],
        startTime:"",
        allTime:"",
        endTime:"",
        page:1,
        pageSize:10,},
      tableData: [{ name: "test" }],
      column: [
        { label: "名称", prop: "workName", width: 180 },
        { label: "题目类型", prop: "testType" },
        { label: "权重", prop: "weight" },
      ],
      total: 0,
      pageSize: 20,
      current: 1,
      selectRows: null,
      selectionData: [],
      bo:{

      }
    };
  },
  computed: {
    course(){
      return this.$store.getters.getTCourse;
    }
  },
  methods: {
    init() {},
    add() {
      this.$router.push("online/add");
    },
    detail() {
      this.$router.push("online/detail");
    },
    query() {
      this.openLoadingView();
      if (this.form.date == null){
        this.form.date = ['',''];
      }
      if (this.form.date[0] instanceof  Date){
        this.form.date[0] = this.form.date[0].formatToLocalDateTime("yyyy-MM-dd HH:mm:ss");
      }
      if (this.form.date[1] instanceof Date){
        this.form.date[1] = this.form.date[1].formatToLocalDateTime("yyyy-MM-dd HH:mm:ss");
      }
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseWorkSetTea/queryEduCourseWorkSetByCourseId?page="+this.form.page+"&pageSize="+this.form.pageSize
          +"&courseId="+this.course.id
          +"&startTime="+this.form.date[0]
          +"&endTime="+this.form.date[1]
          +"&info="+this.form.name
      ).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.tableData.forEach((item)=>{
            if(item.testType==0){
              this.$set(item,"testType","综合试卷")
            }else{
              this.$set(item,"testType","题目")
            }

          })
          this.total = resp.data.data.total;
          this.loadingView.close()
        }
      })
    },
    reset() {
      this.$refs["form"].resetFields();
    },
    // 设置学期
    setSemester(row) {
      if (row) {
        this.selectRows = row;
        this.$refs["semesterRef"].dialog = true;
      }
    },
    // 编辑
    edit(row) {
      if (row) {
        this.$router.push({
          path:"online/add",
          query:{
            id:row.id
          }
        });
      }
    },
    // 预览
    preview(row) {
      if (row) {
        this.$router.push({
          path:"online/preview",
          query:{
            id:row.id,
            studentId:row.studentId,
          }
        });
      }
    },
    onDel(row) {
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseWorkSetTea/deletedEduCourseWorkSet?id="+row.id).then(resp => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功")
         this.query()
        }else{
          this.$message.error("操作失败")
        }
      })
    },
    selectionChange(val) {
      this.selectionData = val;
    },
    handleCurrentChange(page) {
      this.form.page = page;
      this.query();
    },
    handleSizeChange(page){
      this.form.pageSize = page;
      this.query();
    },
  },
};
</script>

<style lang="less" scoped>
.t_work_online {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
  }
}
</style>