<template>
  <el-dialog
    :visible.sync="dialog"
    title="设置时间"
    custom-class="t_s_set_time_dialog"
    width="30%"
  >
    <el-form
      :model="form"
      label-width="120px"
      :rules="rules"
      ref="t_s_setTime_form"
    >
      <el-form-item label="答卷开始时间" prop="answerStartTime">
        <el-date-picker
          v-model="form.answerStartTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="答卷结束时间" prop="answerEndTime">
        <el-date-picker
          v-model="form.answerEndTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="批阅结束时间" prop="reviewEndTime">
        <el-date-picker
          v-model="form.reviewEndTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialog = false">取消</el-button>
      <el-button type="primary" @click="confirm('t_s_setTime_form')">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { UpdateEduCourseWorkSetPlanTime } from "@/libs/api/teacher/online";
export default {
  name: "T_Effective",
  props: ["ids"],
  data() {
    return {
      dialog: false,

      form: {
        answerStartTime: null,
        answerEndTime: null,
        reviewEndTime: null,
      },
      rules: {
        answerStartTime: [{ required: true, message: "请选择答卷开始时间" }],
        answerEndTime: [{ required: true, message: "请选择答卷结束时间" }],
        reviewEndTime: [{ required: true, message: "请选择批阅结束时间" }],
      },
    };
  },
  methods: {
    confirm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;
        // this.form.answerStartTime = this.form.answerStartTime.formatToLocalDateTime()
        // this.form.answerEndTime = this.form.answerEndTime.formatToLocalDateTime()
        // this.form.reviewEndTime = this.form.reviewEndTime.formatToLocalDateTime()
        const { answerStartTime, answerEndTime, reviewEndTime } =
            this.form;
        const data = {
          answerStartTime, //作答开始时间
          answerEndTime, //作答结束时间
          reviewEndTime, //批阅时间
          ids: this.ids, //作业计划关联主键id  多个id逗号相隔
        };
        this.update(data);
      });
    },
    update(data) {
      UpdateEduCourseWorkSetPlanTime(data).then((res) => {
        if(res.code==2000){
          this.$message.success("操作成功")
          this.dialog = false;
          this.$parent.init();
        }else{
          this.$message.error("操作失败")
        }

      });
    },
  },
};
</script>

<style scoped lang="less">
.t_s_set_time_dialog {
}
</style>